export const SUPER_ADMIN = 'Super Admin';
export const OPS_ADMIN = 'Ops Admin';
export const OPERATOR = 'Operator';

export const Roles = {
    [SUPER_ADMIN]: SUPER_ADMIN,
    [OPS_ADMIN]: OPS_ADMIN,
    [OPERATOR]: OPERATOR,
};

export const SuperAdminRolesOptions = [
    {
        label: SUPER_ADMIN,
        value: SUPER_ADMIN,
    },
    {
        label: OPS_ADMIN,
        value: OPS_ADMIN,
    },
    {
        label: OPERATOR,
        value: OPERATOR,
    },
];

export enum PermissionModuleKeys {
    ACCOUNT_APPLICATION = 'ACCOUNT APPLICATION',
    UNIPROFILE_MANAGEMENT = 'UNIPROFILE MANAGEMENT',
    CHANNEL_STORE_MANAGEMENT = 'CHANNEL STORE MANAGEMENT',
    CHANNEL_WITHDRAWAL = 'CHANNEL WITHDRAWAL',
    CHANNEL_LISTING = 'CHANNEL LISTING',
    SERVICE_TEMPLATE = 'SERVICE TEMPLATE',
    INVITE_MEMBER = 'INVITE MEMBER',
    WEB_APP_DEV_STAGE_ACCESS = 'WEB APP DEV STAGE ACCESS',
    HOME_CONFIG = 'HOME CONFIG',
    KIWI_HEALTH = 'KIWI HEALTH',
    KIWI_HEALTH_PAGE = 'KIWI HEALTH PAGE',
    KIWI_HEALTH_PROVIDER = 'KIWI HEALTH PROVIDER',
    AI_CONFIG = 'AI_CONFIG',
    KLARITY_PROVIDER_KIWI_RATING = 'KLARITY_PROVIDER_KIWI_RATING',
    KLARITY_PROVIDER_AIRTABLE_RATING = 'KLARITY_PROVIDER_AIRTABLE_RATING',
    KLARITY_PROVIDER_AIRTABLE = 'KLARITY_PROVIDER_AIRTABLE',
    KLARITY_PROVIDER_KIWI = 'KLARITY_PROVIDER_KIWI',
    KLARITY_PAGE_MANAGEMENT = 'KLARITY_PAGE_MANAGEMENT',
    KLARITY_PROVIDER_RESOURCES = 'KLARITY_PROVIDER_RESOURCES',
    CONTRACT_LINK = 'CONTRACT_LINK',
    GENERAL_SERVICE = 'GENERAL_SERVICE',
    KLARITY_SERVICE = 'KLARITY_SERVICE',
    FEATURED_ITEMS = 'FEATURED_ITEMS',
    REFERRAL_CONFIG = 'REFERRAL_CONFIG',
    CONFIGURATIONS = 'CONFIGURATIONS',
    EHR_ADD_ON = 'EHR_ADD_ON',
    KLARITY_REVIEWS = 'KLARITY_REVIEWS',
    KLARITY_ASSESSMENT = 'ASSESSMENT',
    PROVIDER_REVIEW = 'PROVIDER_REVIEW',
    PROVIDER_REVIEW_PERFORMANCE = 'PROVIDER_REVIEW_PERFORMANCE',
    PROVIDER_REVIEW_LIST = 'PROVIDER_REVIEW_LIST',
    PROVIDER_REVIEW_COMPLAINT = 'PROVIDER_REVIEW_COMPLAINT',
    PROVIDER_REVIEW_DISPUTE = 'PROVIDER_REVIEW_DISPUTE',
}

export const ACCESS_PERMISSION_STORAGE_KEY = 'permission';
export const ACCESS_ROLE_STORAGE_KEY = 'role';

export const limitedEmails = ['helloklarity.com', 'kiwihealth.com', 'boxpractice.com', 'hush.com'];

export const ENV_VAR = 'ENV_VAR';
export const OPS_ENV = 'operation';
export const PROVIDER_ENV = 'provider';

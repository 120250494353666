import { useCallback, useEffect, useState } from 'react';
import { getSpecificConditionListByProviderId } from 'api/operation/helloklarityConfig';
import { message } from 'antd';

const useGetSpecificConditionListByProviderId = (providerId?: number): [string[], boolean, string | null, (pid: number) => void] => {
    const [specificConditionList, setSpecificConditionList] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetch = useCallback(async (pid: number) => {
        if (!pid) return;
        setLoading(true);
        try {
            const res = await getSpecificConditionListByProviderId(pid);

            if (res && !res.error) {
                setSpecificConditionList(res.data.data);
            } else {
                message.error(res?.error);
            }
        } catch (err) {
            setError(err as string);
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (providerId) {
            fetch(providerId);
        }
    }, [fetch, providerId]);

    return [specificConditionList, loading, error, fetch];
};

export default useGetSpecificConditionListByProviderId;

import React from 'react';
import { getHomeInfo } from 'api/provider';
import { HomeInfo } from 'types/provider';
import HomeStore from 'store/Home';
import { checkIsRegularUser } from 'utils/common';

let globalData:HomeInfo;
const useProviderHomeInfo = (isOperation?: boolean):[HomeInfo | undefined, boolean, ()=>void] => {
    const [,setHomeStore] = HomeStore.useStore();
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState<HomeInfo>(globalData);
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await getHomeInfo();
            if (!result.error) {
                const data:HomeInfo = result?.data?.data;
                const resData = data ? {
                    ...data,
                    isRegularUser: checkIsRegularUser(data.signUpDate),
                } : data;
                globalData = resData;
                setItems(resData);
                setHomeStore('reload', false);
                setHomeStore('data', resData);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        if (!loading && !isOperation) {
            fetchData();
        }
    }, []);

    return [items, loading, fetchData];
};

export default useProviderHomeInfo;

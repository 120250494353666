import { getSpecificConditionDetail } from 'api/operation/helloklarityConfig';
import { useCallback, useEffect, useState } from 'react';
import { IGetSpecificConditionDetailInput, IGetSpecificConditionDetailResponse } from 'types/helloKlarity';

const useGetSpecificConditionDetail = (providerId?: number, condition?: string, auto?: boolean): [IGetSpecificConditionDetailResponse | undefined, boolean, string, (fetchParams: IGetSpecificConditionDetailInput) => Promise<void>] => {
    const [data, setData] = useState<IGetSpecificConditionDetailResponse>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetch = useCallback(async (fetchParams: IGetSpecificConditionDetailInput) => {
        if (!fetchParams.providerId || !fetchParams.condition || loading) {
            return;
        }

        setLoading(true);

        try {
            const res = await getSpecificConditionDetail(fetchParams);

            if (res && !res.error) {
                setData(res.data?.data);
            } else {
                setError(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, [loading]);

    useEffect(() => {
        if (auto && providerId && condition && !loading) {
            fetch({ providerId, condition });
        }
    }, [auto, providerId, condition, loading]);

    return [data, loading, error, fetch];
};

export default useGetSpecificConditionDetail;

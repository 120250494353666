import React from 'react';
import { TResource } from 'types/common';
import { getKlarityResources } from 'api/operation';
import { providerGetKlarityResources } from 'api/provider';


let globalData: TResource[] = [];

const useKlarityResource = (isOps?: boolean): [TResource[] | undefined, boolean, () => void] => {
    const loading = React.useRef(false);
    const [items, setItems] = React.useState<TResource[]>();
    const fetchData = async () => {
        if (loading.current) {
            return;
        }
        loading.current = true;
        try {
            let api = providerGetKlarityResources;
            if (isOps) {
                api = getKlarityResources;
            }
            const result = await api({ current: 1, size: 1000 });
            if (!result.error) {
                const resData = result?.data?.data?.records || [];
                globalData = resData;
                setItems(resData);
            }
            loading.current = false;
        } catch (e) {
            console.error(e);
        }
        loading.current = false;
    };
    React.useEffect(() => {
        fetchData();
    }, []);

    return [items, loading.current, fetchData];
};

export default useKlarityResource;

import React from 'react';
import { TResourceHistory } from 'types/common';
import { getKlarityResourcesHistory } from 'api/operation';


let globalData: TResourceHistory[] = [];

const useKlarityResourceHistory = (id?: number): [TResourceHistory[], boolean, () => void] => {
    const loading = React.useRef(false);
    const [items, setItems] = React.useState<TResourceHistory[]>([]);
    const fetchData = async () => {
        if (loading.current || !id) {
            return;
        }
        loading.current = true;
        try {
            let api = getKlarityResourcesHistory;
            const result = await api(id);
            if (!result.error) {
                console.log({
                    result
                })
                const resData = result?.data?.data?.records || [];
                globalData = resData;
                setItems(resData);
            }
            loading.current = false;
        } catch (e) {
            console.error(e);
        }
        loading.current = false;
    };
    React.useEffect(() => {
        fetchData();
    }, [id]);

    return [items, loading.current, fetchData];
};

export default useKlarityResourceHistory;

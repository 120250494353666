import { getProspectSettingInfo } from 'api/prospect';
import { useEffect, useState } from 'react';
import { TProspectAvaConfig } from 'types/ai';

const useProspectSettingInfo = (): [TProspectAvaConfig | undefined, boolean, () => void] => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<TProspectAvaConfig>();
    const [error, setError] = useState('');

    const fetch = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            const response = await getProspectSettingInfo();

            if (response && !response.error) {
                setData(response.data?.data);
            } else {
                setError(response?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    return [data, loading, fetch];
};

export default useProspectSettingInfo;

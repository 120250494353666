import { getProviderNotificationConfig } from 'api/prospect';
import { useEffect, useState } from 'react';
import { TNotifyInfo } from 'types/ai';

const useProviderNotificationConfig = (): [TNotifyInfo | undefined, boolean, () => void] => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<TNotifyInfo>();
    const [error, setError] = useState('');

    const fetch = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            const response = await getProviderNotificationConfig();

            if (response && !response.error) {
                setData(response.data?.data);
            } else {
                setError(response?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    return [data, loading, fetch];
};

export default useProviderNotificationConfig;
